.employee-section-header {
    margin-top: 1vh;
    margin-bottom: 1.5vh;
    color: var(--primary-color);
}

.employee-section-header-border {
    margin-top: 1vh;
    margin-bottom: 1.5vh;
    width: 22vw;
    min-width: 250px;
    border: 1px solid lightgrey;
}

.employee-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
}


.employee-input-container-wide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: fit-content;
}

.employee-root-container {
    display: flex !important;
    max-width: 70vw !important;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
}

.employee-div-container {
    width: 30vw;
    display: flex;
    max-width: 450px;
    min-width: 150px;
    padding-bottom: 2vh;
}

.employee-div-container-sm {
    width: 20vw;
    display: flex;
    max-width: 250px;
    min-width: 150px;
    padding-bottom: 2vh;
    padding-top: 1vh;
}


.employee-input-label {
    display: flex !important;
    min-width: 200px;
    position: initial !important;
    font-weight: 500;
    color: #000 !important;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px) !important;
}

.employee-input-label-spaced {
    margin-bottom: 10px;
}

.employee-input-text {
    margin: 1vh 0 4% 0;
    border: 2px solid !important;
    border-radius: 5px;
    width: 20vw !important;
    max-width: 250px;
    min-width: 150px !important;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: black;
    padding: 3px 3px 3px 5px;
}

.employee-input-text.sm-text {
    font-size: 0.875rem;
    font-weight: normal;
}

.employee-input-text:focus {
    border-color: var(--primary-color) !important;
    border-width: 2px !important;
    outline: none !important;
}

.employee-input-text-md {
    margin: 0;
    height: 38px;
    width: 15vw !important;
    max-width: 230px !important;
    min-width: 125px !important;
    font-family: 'Proxima Nova', sans-serif;
    color: black;
}

.employee-input-text-md:focus {
    border-color: var(--primary-color) !important;
    border-width: 2px !important;
    outline: none !important;
}

.employee-input-text-sm {
    width: 50px !important;
    white-space: nowrap;
}

.employee-input-text:disabled {
    opacity: 0.3;
}

.employee-button-div {
    display: flex;
}

.employee-badge-div {
    display: flex;
    align-items: flex-end;
}

.employee-badge-container {
    display: flex;
    width: 30vw;
    max-width: 375px;
    min-width: 250px;
    flex-direction: column;
}

.employee-badge-container.active {
    background-color: rgba(191, 191, 191, 0.2);
    margin: 20px 0 20px 0;
    padding: 15px;
}

.employee-new-badge-label {
    color: var(--primary-color) !important;
    font-weight: bold !important;
}

.employee-cancel-link {
    cursor: pointer;
    text-align: end;
    margin-top: 5px;
    display: flex;
    font-size: max(min(max(2vh, 2vw), 19px), 8px);
    font-weight: normal;
    font-family: 'Proxima Nova', sans-serif;
    color: #005BD4;
    margin-left: 10px;
    text-underline: #005BD4;
    text-decoration: underline;
    background-color: transparent;
    border-width: 0;
}

.employee-link {
    margin-left: 15px !important;
}

.employee-email-container {
    display: flex;
    flex-wrap: wrap;
}

.employee-email-input {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-top: 1vh;
    align-items: baseline;
    padding-bottom: 2vh;
}

.employee-email-subtext {
    color: #A4A2A2FF;
    font-style: italic;
    margin-left: 15px !important;
}

.employee-tooltip-icon {
    font-size: clamp(10px, (2vh + 2vw) / 2, 20px);
    padding-left: 5px;
    width: .8em !important;
    height: .8em !important;
    color: var(--primary-color);
}

.employee-tooltip-label {
    display: flex !important;
    align-items: center;
    transform: unset;
    margin-top: 10px;
    position: initial;
    color: #000 !important;
}

.employee-tooltip-label-text {
    display: flex;
    transform: unset;
    align-items: center;
    position: initial;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px) !important;
    font-weight: normal !important;
    color: #000; /* Assuming BLACK is a constant representing black color */
}

.employee-location-row {
    border-bottom: 1px solid lightgrey !important;
}

.employee-location-label {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px) !important;
    font-weight: normal !important;
}

.employee-location-header {
    margin-left: max(4.5%, 45px) !important;
    font-size: clamp(16px, (2vw + 2vh)/2, 18px) !important;
    font-weight: normal !important;
}


@media (max-width: 960px) {
    .employee-input-text {
        width: 80vw;
        max-width: 300px;
        min-width: 150px;
    }
    .employee-div-container {
        width: 80vw;
    }
    .employee-root-container {
        max-width: unset;
    }
    .employee-email-input {
        flex-direction: column;
    }
    .employee-link {
        margin-left: 0px !important;
    }
    .employee-location-label {
        width: 78%;
    }
    .employee-location-header {
        margin-left: max(10%, 45px) !important;
    }
}

@media (max-width: 600px) {
    .employee-input-text {
        width: 55vw !important;
        max-width: 300px;
        min-width: 150px;
    }
    .employee-input-label {
        min-width: 70px;
        max-width: 200px;
    }
    .employee-tooltip-label {
        transform: unset;
        margin-left: 0;
        margin-top: 0;
        min-width: 70px;
        max-width: 200px;
        height: 40px;
        font-size: calc(0.9em * 1.25);
        align-items: center;
    }
    .employee-location-label {
        width: 78%;
    }
}
